<template>
  <v-container fill-height fluid>
    <v-row justify="center" align="center">
      <v-col md="4" sm="10" cols="12">
        <v-card
          elevation="4"
          outlined
          color="secondary"
          min-height="50vh"
          dark
        > 
            <v-card-title class="text-success">
              {{$ml.get('useq')}}
            </v-card-title>

            <v-card-subtitle class="text-subtitle">
              {{$ml.get('signin')}}
            </v-card-subtitle>
            <v-card-text class="text-text">
                <v-row justify="center">
                  <v-col md="10" cols="12">
                    <p v-text="$ml.get('pleaseent')"></p>
                  </v-col>
                </v-row>
                <v-row justify="center">
              <v-col md="10" cols="12">          
                <v-form
                    ref="form"
                    v-model="validLogin" 
                    @submit.prevent="signIn()"
                  >
                    <v-text-field
                        v-model="clientID"
                        :counter="50"
                        :rules="clientRules"
                        label="Client ID"
                        outlined
                        required
                        background-color="primary"
                        color="white"
                    ></v-text-field>
                    <!--<v-text-field
                        v-model="username"
                        :counter="50"
                        label="Username"
                        outlined
                        background-color="primary"
                        color="white"
                    ></v-text-field>-->

                    <v-row justify="space-around">
                      <v-col cols="auto">
                        <v-btn
                          :disabled="!validLogin"
                          color="success"
                          @click="signIn"
                        >
                          {{$ml.get('signin')}}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-col>
              </v-row>
            </v-card-text>
        </v-card>
      </v-col>

      <v-col md="6" sm="10" cols="12">
        <v-card
          elevation="4"
          outlined
          color="secondary"
          min-height="50vh"
          dark
          v-if="cardType === '1'"
        >
          <v-card-title class="text-success">
            {{$ml.get('getq')}}
          </v-card-title>
          <v-card-subtitle class="text-subtitle">
            {{$ml.get('create')}}
          </v-card-subtitle>
          <v-card-text class="text-text">
            <p v-text="$ml.get('textcreate')"></p>
            <p v-text="$ml.get('textcreate2')"></p>
          </v-card-text>
          <v-row justify="center" align="center">
            <v-col cols="auto">
              <a><v-btn color="success" @click="cardType='1'">{{$ml.get('signup')}}</v-btn></a>
            </v-col>
          </v-row>
        </v-card>

        <v-card
          elevation="4"
          outlined
          color="secondary"
          min-height="50vh"
          dark
          v-if="cardType === '0'"
        >
          <v-card-title class="text-success">
            {{$ml.get('getq')}}
          </v-card-title>
          <v-card-subtitle class="text-subtitle">
            {{$ml.get('signup')}}
          </v-card-subtitle>
          <v-card-text class="text-text">
            <p v-html="$ml.get('signuptxt')">
            </p>

            <v-row justify="center">
              <v-col md="10" cols="12">          
                <v-form ref="form" v-model="validRegister">
                  <v-row>
                    <v-col md="6" cols="12">
                      <v-text-field
                        v-model="firstName"
                        :counter="50"
                        :rules="firstNameRules"
                        :label="$ml.get('firstn')"
                        outlined
                        required
                        background-color="primary"
                        color="white"
                      ></v-text-field>
                    </v-col>
                    <v-col md="6" cols="12">
                      <v-text-field
                        v-model="name"
                        :counter="50"
                        :rules="nameRules"
                        :label="$ml.get('lastn')"
                        outlined
                        required
                        background-color="primary"
                        color="white"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-text-field
                    v-model="enterprise"
                    :counter="50"
                    :label="$ml.get('company')"
                    outlined
                    background-color="primary"
                    color="white"
                  ></v-text-field>
                  <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    label="E-mail*"
                    outlined
                    required
                    background-color="primary"
                    color="white"
                  ></v-text-field>
                  <v-text-field
                    v-model="phone"
                    :label="$ml.get('phone')"
                    outlined
                    background-color="primary"
                    color="white"
                  ></v-text-field>
                  <v-checkbox
                    @click="product_computed"
                    v-model="platform"
                    :label="$ml.get('platformcheck')"
                  ></v-checkbox>
                  <!--
                  <v-select
                    v-model="product"
                    :items="products"
                    item-text="text"
                    item-value="value" 
                    :rules="[v => !!v || 'Item is required']"
                    :label="$ml.get('product')"
                    required
                    outlined
                    background-color="primary"
                    color="white"
                  ></v-select>-->
                  <p v-text="$ml.get('required')"></p>
                  <v-row justify="center" v-if="registerLoading">
                    <v-col cols="auto">
                    <v-progress-circular
                      indeterminate
                      color="red"
                    ></v-progress-circular>
                    </v-col>
                  </v-row>
                  <v-row justify="space-around">
                    <v-col cols="auto">
                      <v-btn
                        :disabled="!validRegister"
                        color="success"
                        @click="validate"
                      >
                        {{$ml.get('validate')}}
                      </v-btn>
                    </v-col>
                    <v-col cols="auto">
                      <v-btn
                        color="warning"
                        @click="reset"
                      >
                        {{$ml.get('reset')}}
                      </v-btn>
                    </v-col>
                    <!--
                    <v-col cols="auto">
                      <v-btn
                        color="warning"
                        @click="product_computed"
                      >
                        Prodcut
                      </v-btn>
                      {{product}}
                    </v-col>
                    -->
                  </v-row>
                  
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card
          elevation="4"
          outlined
          color="secondary"
          min-height="50vh"
          dark
          v-if="cardType === '2'"
        >
          <v-card-title class="text-success">
            QUANTEEC
          </v-card-title>
          <v-card-subtitle class="text-subtitle">
            {{$ml.get('okaccount')}}
          </v-card-subtitle>
          <v-card-text class="text-text">
            <p v-text="$ml.get('emailacc')"></p>

            <!--<p>{{$ml.get('url')}} <a :href="result.platformURL" target="_blank" style="color: white;">{{result.platformURL}}</a></p>-->
            <!--<p>{{$ml.get('username')}} <strong>{{result.username}}</strong></p>
            <p>{{$ml.get('passwd')}} <strong>{{result.password}}</strong></p>-->
          </v-card-text>
        </v-card>

      </v-col>
    </v-row>

    <v-dialog
      v-model="isError">
      <v-card
        dark
      >
        <v-card-title>
          {{$ml.get('erroracc')}}
        </v-card-title>
        <v-card-text>
          {{errorText}}
        </v-card-text>
        <v-card-actions>
          <v-btn @click="isError = false" color="warning">{{$ml.get('close')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="errorLogin">
      <v-card
        dark
      >
        <v-card-title>
          {{$ml.get('error')}}
        </v-card-title>
        <v-card-text>
          <span v-html="$ml.get('errortype')"></span>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="errorLogin = false" color="warning">{{$ml.get('close')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import axios from 'axios';
import {BASE_URL} from '@/services/base-url';
var Base64={_keyStr:"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",encode:function(e){var t="";var n,r,i,s,o,u,a;var f=0;e=Base64._utf8_encode(e);while(f<e.length){n=e.charCodeAt(f++);r=e.charCodeAt(f++);i=e.charCodeAt(f++);s=n>>2;o=(n&3)<<4|r>>4;u=(r&15)<<2|i>>6;a=i&63;if(isNaN(r)){u=a=64}else if(isNaN(i)){a=64}t=t+this._keyStr.charAt(s)+this._keyStr.charAt(o)+this._keyStr.charAt(u)+this._keyStr.charAt(a)}return t},decode:function(e){var t="";var n,r,i;var s,o,u,a;var f=0;e=e.replace(/[^A-Za-z0-9\+\/\=]/g,"");while(f<e.length){s=this._keyStr.indexOf(e.charAt(f++));o=this._keyStr.indexOf(e.charAt(f++));u=this._keyStr.indexOf(e.charAt(f++));a=this._keyStr.indexOf(e.charAt(f++));n=s<<2|o>>4;r=(o&15)<<4|u>>2;i=(u&3)<<6|a;t=t+String.fromCharCode(n);if(u!=64){t=t+String.fromCharCode(r)}if(a!=64){t=t+String.fromCharCode(i)}}t=Base64._utf8_decode(t);return t},_utf8_encode:function(e){e=e.replace(/\r\n/g,"\n");var t="";for(var n=0;n<e.length;n++){var r=e.charCodeAt(n);if(r<128){t+=String.fromCharCode(r)}else if(r>127&&r<2048){t+=String.fromCharCode(r>>6|192);t+=String.fromCharCode(r&63|128)}else{t+=String.fromCharCode(r>>12|224);t+=String.fromCharCode(r>>6&63|128);t+=String.fromCharCode(r&63|128)}}return t},_utf8_decode:function(e){var t="";var n=0;var r=c1=c2=0;while(n<e.length){r=e.charCodeAt(n);if(r<128){t+=String.fromCharCode(r);n++}else if(r>191&&r<224){c2=e.charCodeAt(n+1);t+=String.fromCharCode((r&31)<<6|c2&63);n+=2}else{c2=e.charCodeAt(n+1);c3=e.charCodeAt(n+2);t+=String.fromCharCode((r&15)<<12|(c2&63)<<6|c3&63);n+=3}}return t}}
  export default {
    name: 'Home',
    data: () => ({
        baseUrl: '',//'http://localhost:8087',
        validLogin: false,
        validRegister: false,
        registerLoading: false,
        errorLogin: false,
        isError: false,
        errorText: "Please retry later or contact QUANTEEC at: support@quanteec.com",
        clientID: '',
        username: '',
        clientRules: [
            v => !!v || 'Client ID is required'
        ],
        cardType: '0',
        name: '',
        firstName: '',
        enterprise: '',
        email: '',
        phone: '',
        product: 'plugin',
        platform: false,
        products: [
          {text: 'Quanteec Platform', value: 'platform'},
          {text: 'Quanteec Plug-In', value: 'plugin'}
        ],
        nameRules: [
          v => !!v || 'Name is required'
        ],
        firstNameRules: [
          v => !!v || 'First name is required'
        ],
        phoneRules: [
          v => !!v || 'Phone is required',
          v => v.length >= 10 || 'Phone is too short',
          v=> /^[+]?[\s./0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/g.test(v) || 'Phone must be valid'
        ],
        emailRules: [
          v => !!v || 'E-mail is required',
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        result: {}
    }),
    mounted() {
      let inputProduct = this.$route.params['product'];
      this.reset();
      if (inputProduct === 'plugin' || inputProduct === 'platform') {
        this.product = inputProduct;
      }      
    },
    computed: {
      product_computed() {
        if (this.platform)
          this.product = "platform";
        else
          this.product = "plugin";
      }
    },
    methods: {
     signIn() {
       let req = {
         url: `${BASE_URL}/api/clients/` + this.clientID,
         method: 'get'
       }
       axios(req).then((response) => {
         let data = response.data;
         if (data.destination) {
           let newURL = data.destination;
           if (this.username && this.username !== "") {
             newURL += '/' + Base64.encode(this.username);
           }
           window.location = newURL;
         }
         console.log(data);
       }).catch(err => {
         this.errorLogin = true;
         console.log(err);
       })
     },
     validate() {
        let data = {
            contactFirstName: this.firstName,
            contactName: this.name,
            email: this.email,
            phone: this.phone,
            product: this.product
        }
        if (!!this.enterprise) {
          data.enterpriseName = this.enterprise;
        }
        let req = {
          url: `${BASE_URL}/api/clients`,
          method: 'post',
          data: data
        }
        
        this.registerLoading = true;
        axios(req).then((response) => {
          this.registerLoading = false;
          this.result = response.data;
          this.cardType = "2";
        }).catch(err => {
          if(err.response) {
            this.errorText = err.response.data;
          } else {
            this.errorText = "Please retry later or contact QUANTEEC at: support@quanteec.com";
          }
          this.isError = true;
          this.registerLoading = false;
        })

      },
      reset() {
        this.name = '';
        this.firstName = '';
        this.enterprise = '';
        this.email = '';
        this.phone = '';
        this.product = 'plugin';
        this.result = {};
      }
    }
  }
</script>

<style scoped>
.text-success {
  color: #14BF98;
}
.text-subtitle {
  color: #fff !important;
  font: 700 1.75rem/2.125rem "Montserrat", sans-serif;
}

.text-text {
  color: #dfe5ec !important;
  font: 400 1rem/1.5625rem "Open Sans", sans-serif;
}

.borderRight {
    border-right: solid 1px grey;
}
</style>
