<template>
  <Home/>
</template>

<script>
  import Home from '../components/Home'

  export default {
    name: 'HomePage',

    components: {
      Home,
    },
  }
</script>
